<template>
  <div class="record">
    <div class="box">
      <div class="head">
        <div class="left">
          <div class="time" @click="show = true">
            <span>{{ pay_time }}</span>月
            <van-icon name="arrow-down" />
          </div>
          <div class="expenditure">
            支出 <span>￥{{ total.toFixed(2) }}</span>
          </div>
        </div>
        <div class="selectType" @click="sheetShow = !sheetShow">
          <span>{{ selectItem.name }}</span>
          <img src="../../assets/image/blackDown.png" />
          <!-- <div class="select" :style="{ display: isOpen ? 'block' : 'none' }">
            <div
              class="list"
              :class="{ act: act == index }"
              v-for="(item, index) in typeName"
              :key="index"
              @click.self.stop="typeChange(index, item.contract_fee_name)"
            >
              {{ item.contract_fee_name }}
            </div>
          </div> -->
        </div>
      </div>
      <div class="content" v-if="list">
        <van-list v-model:loading="loading" :finished="finished" :offset="30" finished-text="没有更多了" @load="onLoad">
          <div class="list" v-for="item in list" :key="item.bill_offline_id" @click="
              $router.push({
                path: '/contribute/recordDetail',
                query: { id: item.bill_offline_id }
              })
            ">
            <div class="left">
              <img :src="getBillImg(item.bill_offline_key)" alt="" />
            </div>
            <div class="right">
              <div class="nameLeft">
                <div class="name">
                  {{ item.bill_offline_name }}
                </div>
                <div class="type">充值缴费</div>
                <div class="time">时间：{{ item.pay_time }}</div>
              </div>
              <div class="nameRight">-{{ item.key_pay_price }}</div>
            </div>
          </div>
        </van-list>
      </div>
      <div class="custom-image" image="default" v-else>
        <img src="../../assets/image/empty.png" alt="" />
        <p>还没有任何数据哦~"</p>
      </div>

      <van-action-sheet v-model:show="sheetShow" title="选择类型" :close-on-popstate="true" :actions="actions" @select="onSelect" />
    </div>
    <van-popup v-model:show="show" round position="bottom" :lock-scroll="false">
      <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" @cancel="show = false" :formatter="formatter" @confirm="confirm" />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'record',
  data () {
    return {
      sheetShow: false,
      actions: [
        { name: '全部', value: 0 },
        { name: '日常费用', value: 20 },
        { name: '基础费用', value: 10 },
        { name: '一次性费用', value: 9 }
      ],
      selectItem: {
        name: '全部',
        value: ''
      },
      act: 0,
      isOpen: false,
      pay_time: '',
      list: [],
      currentDate: '',
      loading: false,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      pageTotal: 0,
      total: 0,
      show: false
    };
  },
  created () {
    this.pay_time = this.$common.getMonth(new Date());
    this.currentDate = new Date();
    this.getList();
    this.getFillName();
  },
  methods: {
    onSelect (e) {
      this.selectItem = e;
      this.sheetShow = false;
      this.pageNum = 1;
      this.getList();
    },
    /**
     * 上拉加载
     */
    onLoad () {
      this.loading = false;
      // console.log(arr.length)
      if (this.pageNum >= Math.ceil(this.pageTotal / this.pageSize)) {
        this.finished = true;
      } else {
        this.pageNum++;
        this.getList();
      }
    },
    typeChange (index, name) {
      console.log(index);
      this.act = index;
      this.isOpen = false;
      this.pageNum = 1;
      this.getList(name);
    },
    getFillName () {
      this.$require.HttpPost('/h5/payment/getPayProject').then((res) => { });
    },
    formatter (type, val) {
      if (type === 'year') {
        return `${val}年`;
      }
      if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    confirm (value) {
      this.pay_time = this.$common.getMonth(value);
      this.list = [];
      this.getList();
      this.show = false;
    },
    getList () {
      this.total = 0;
      this.$require
        .HttpPost('/h5/payment/getPayHistory', {
          pay_time: this.pay_time,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          contract_fee_name: this.selectItem.name,
          bill_key: this.selectItem.value
        })
        .then((res) => {
          if (res.data) {
            res.data.list &&
              res.data.list.forEach((item) => {
                this.total += item.key_pay_price;
              });
            this.list = res.data.list;
            this.pageTotal = res.data.total;
          }
          this.loading = false;
        });
    },
    getBillName (key) {
      switch (key) {
        case 10:
          return '水费';
        case 20:
          return '电费';
        case 30:
          return '气费';
        case 40:
          return '商铺租金';
        default:
          return '';
      }
    },
    getBillImg (key) {
      switch (key) {
        case 20:
          return require('../../assets/image/shui.png');
        case 10:
          return require('../../assets/image/zujinIcon.png');
        default:
          return require('../../assets/image/other.png');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.record {
  padding: 0 24px;

  .box {
    box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
    border-radius: 30px;
    min-height: calc(100vh - 60px);
    margin-top: 30px;

    .head {
      height: 143px;
      border-bottom: 1px solid #efefef;
      padding: 0 24px;
      background: #ffffff;
      position: sticky;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        .time {
          font-size: 28px;
          color: #b9b9b9;
          padding-top: 30px;

          span {
            font-size: 36px;
            font-weight: bold;
            color: #529100;
          }
        }

        .expenditure {
          font-size: 28px;
          color: #333333;
          margin-top: 10px;
        }
      }

      .selectType {
        position: relative;
        min-width: 180px;
        padding: 22px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 28px;
        background: #ffffff;
        border: 1px solid #dedede;
        border-radius: 36px;

        img {
          width: 20px;
          height: 20px;
        }

        .select {
          position: absolute;
          width: 180px;
          top: 75px;
          left: -2px;
          border: 2px solid #efefef;
          box-shadow: 0 5px 15px 3px #efefef;
          transition: display 1s;

          .list {
            background: #ffffff;
            border-bottom: 1px solid #efefef;
            padding: 24px;
            box-sizing: border-box;

            &:last-child {
              border: none;
            }
          }

          .act {
            background-color: #529100;
            color: #ffffff;
          }
        }
      }
    }

    .content {
      .list {
        padding: 0 20px;
        height: 198px;
        display: flex;

        .left {
          margin-right: 20px;

          img {
            width: 78px;
            height: 78px;
            margin-top: 35px;
          }
        }

        .right {
          width: 554px;
          height: 100%;
          border-bottom: 1px solid #efefef;
          display: flex;
          justify-content: space-between;

          .nameLeft {
            font-size: 28px;

            .name {
              color: #000000;
              margin-top: 40px;
            }

            .type {
              color: #b9b9b9;
              margin-top: 15px;
            }

            .time {
              color: #b9b9b9;
              margin-top: 10px;
            }
          }

          .nameRight {
            font-size: 32px;
            font-weight: bold;
            color: #000000;
            margin-top: 40px;
          }
        }
      }
    }
  }

  .custom-image {
    text-align: center;

    img {
      width: 321px;
      margin-top: 181px;
    }

    p {
      font-size: 24px;
      color: #529100;
    }
  }
}
</style>
